<template>
  <div class="visit-plan-customer" :class="{ visited: isDone }"
    @click="handleClick">
    <p v-if="isDone" class="visit-time">
      <icon name="fa-check" /> {{ visitTime }}
    </p>
    <h1>{{ customer.name }}</h1>
    <div class="customer-info" v-html="contents" />
  </div>
</template>

<script>
  import moment from 'moment';
  import EventNames from '@/enums/event-names';
  import { defineAsyncComponent } from 'vue';
  import translate from '@/components/Mixins/Translate';

  const ItemDetails = defineAsyncComponent(() => import('../Pages/ItemDetails'));

  /**
   * This component is a customer in a visit plan list.
   * It is marked green if the customer has been visited.
   * When clicked, it opens Take Picture with a customer selected, if the customer
   * has not yet been visited.
   * If the customer has been visited, it opens ItemDetails instead.
   */
  export default {
    name: 'visit-plan-customer',
    mixins: [translate],
    props: {
      customer: {
        // type: Object,
        required: true,
      },
      sortBy: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        renderers: {
          distance: (item) => `<p><b>${Math.round(item.distance || 0)} Kilometer</b><br/>
${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          name: (item) => `<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          city: (item) => `<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          address: (item) => `<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
          // eslint-disable-next-line max-len
          postalCode: (item) => `<p>${item.address}<br/>${item.postalCode} ${item.city}<br/>${item.extra1}</p>`,
        },
      };
    },
    computed: {
      isDone() {
        return this.customer.visits && this.customer.visits.length > 0;
      },
      visitTime() {
        if (!this.customer.visits || this.customer.visits.length === 0) return '';
        return moment.utc(this.customer.visits[0].visitTime).local().format('D MMM YYYY');
      },
      contents() {
        return this.renderers[this.sortBy](this.customer);
      },
    },
    methods: {
      handleClick() {
        if (this.isDone) {
          console.log('Visit details');
          this.$store.commit('pushPopup', {
            component: ItemDetails,
            title: this.x('visit'),
            params: {
              id: this.customer.visits[0].id,
            },
          });
        } else {
          this.$store.commit('VisitPlanStore/setPreselectedCustomer', this.customer);
          this.$bus.emit(EventNames.takePicture);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .visit-plan-customer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding: 10px 10px;

    h1 {
      color: #656565;
    }

    .customer-info {
      font-size: 10px;
      color: #a0a0a0;
    }

    .visit-time {
      float: right;
      font-size: 11px;
      color: var(--ThemeBackgroundColor)
    }
  }
</style>
