<template>
  <div>
    <template v-if="isLoading">
      <div id='contentLoader'>
        <i class='fa fa-cog fa-spin'></i>{{ x('loadingDots') }}
      </div>
    </template>
    <template v-if="!isLoading">
      <RefreshButton v-if="refreshAvailable"/>
      <VisitPlanList v-if="showVisitPlanList" />
      <VisitPlan v-else :visit-plan-id="visitPlans[0].id" />
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import VisitPlan from '@/components/VisitPlans/VisitPlan';
  import VisitPlanList from '@/components/VisitPlans/VisitPlanList';
  import RefreshButton from '@/components/VisitPlans/RefreshButton';
  import translate from '../Mixins/Translate';

  /**
   * When pressing the Visit Plans menu item, it should show a list of visit plans (VisitPlanList) if multiple
   * exist, but if only one exists, it should go directly to that one (VisitPlan).
   * This container has the purpose of loading visit plans and showing the correct component.
   */
  export default {
    name: 'visit-plan-container',
    components: {
      VisitPlan,
      VisitPlanList,
      RefreshButton,
    },
    mixins: [translate],
    computed: {
      showVisitPlanList() {
        return this.visitPlans.length !== 1;
      },
      ...mapState('VisitPlanStore', ['visitPlans', 'isLoading', 'refreshAvailable']),
    },
    async mounted() {
      await this.$store.dispatch('LocationStore/requestLocation');
    },
  };
</script>

<style scoped>

</style>
