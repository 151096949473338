<template>
  <div class='element materialShadow'>
    <button class="lsc-button refresh-button"
            @click="refresh"
    >
      {{ x('visitPlanUpdateAvailable') }}
    </button>
  </div>
</template>

<script>
  import translate from '@/components/Mixins/Translate';

  export default {
    name: 'RefreshButton',
    mixins: [translate],
    methods: {
      async refresh() {
        await this.$store.dispatch('VisitPlanStore/loadVisitPlansForUser');
      },
    },
  };
</script>

<style scoped>
  .refresh-button {
    width: 100%;
    text-align: center;
  }
</style>
