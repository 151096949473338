<template>
  <div class='visitPlan element materialShadow'>
    <template v-if="visitPlan != null">
      <div class="green-card">
        <h1>{{  x('visitPlan') }} ({{ completionPercentage }}%)</h1>
        {{ periodFrom }} - {{ periodTo }}
      </div>

      <map-component class="map" :points="customerLocations" />

      <div class="customer-list">
        <div class="search">
          <input title="search" type="text" class="lsc-input lsc-rounded list_searchbar"
                 v-model="searchText" :placeholder='x("searchDots")'><i class='fa fa-search'></i>
        </div>
        <div class="sort">
          Sorteret efter: <a @click="toggleSortOptions()" class="defaultSort">{{ x(sortLabel) }}</a>
          <a @click="toggleSortDirection()" class="sort_direction">
            <icon v-if="sortAscending" name="fa-sort-amount-asc"/>
            <icon v-else name="fa-sort-amount-desc"/>
          </a>

          <ul class='sortList' v-show="sortOptionsVisible">
            <li v-if="showDistanceSorting">
              <a @click="setSortProperty('distance')">{{ x('distance') }}</a>
            </li>
            <li><a @click="setSortProperty('name')">{{ x('name') }}</a></li>
            <li><a @click="setSortProperty('city')">{{ x('city') }}</a></li>
            <li><a @click="setSortProperty('address')">{{ x('address') }}</a></li>
            <li><a @click="setSortProperty('postalCode')">{{ x('postalCode') }}</a></li>
          </ul>
        </div>
        <div class="customers">
          <visit-plan-customer
            v-for="customer in filteredCustomers.list"
            :key="customer.id"
            :customer="customer"
            :sort-by="sortProperty"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState, useStore } from 'vuex';
  import { reactive } from 'vue';
  import translate from '@/components/Mixins/Translate';
  import moment from 'moment';
  import VisitPlanCustomer from '@/components/VisitPlans/VisitPlanCustomer';
  import MapComponent from '@/components/MapComponent';
  import useCustomerSorting from '@/composables/useCustomerSorting';
  import useCustomerFilter from '@/composables/useCustomerFilter';

  export default {
    name: 'visit-plan',
    components: { MapComponent, VisitPlanCustomer },
    mixins: [translate],
    props: {
      visitPlanId: {
        type: String,
        required: true,
      },
    },
    emits: ['didLoad'],
    setup(props) {
      const store = useStore();
      const visitPlan = reactive(store.getters['VisitPlanStore/getVisitPlanById'](props.visitPlanId));

      const { sortedCustomers, ...customerSorting } = useCustomerSorting(visitPlan.customers);
      const customerFilter = useCustomerFilter(sortedCustomers);
      return {
        visitPlan,
        sortedCustomers,
        ...customerSorting,
        ...customerFilter,
      };
    },
    computed: {
      periodFrom() {
        return moment.utc(this.visitPlan.periodFrom).local().format('D MMM YYYY');
      },
      periodTo() {
        return moment.utc(this.visitPlan.periodTo).local().format('D MMM YYYY');
      },
      customerLocations() {
        return this.visitPlan.customers.map((c) => ({
          latitude: c.latitude,
          longitude: c.longitude,
          style: (c.visits && c.visits.length > 0) ? 'normal' : 'alert',
        }));
      },
      customerCount() {
        return this.visitPlan.customers.length;
      },
      visitedCustomerCount() {
        return this.visitPlan.customers.filter((customer) => customer.visits && customer.visits.length > 0).length;
      },
      completionPercentage() {
        return Math.round((this.visitedCustomerCount * 100) / this.customerCount);
      },
      showDistanceSorting() {
        return this.isGeolocationSupported && !this.locationError;
      },
      ...mapState('LocationStore', ['isGeolocationSupported', 'isLoadingLocation', 'location', 'locationError']),
    },
    watch: {
      location(newValue) {
        this.$store.commit('VisitPlanStore/calculateCustomerDistance', newValue);
      },
      visitPlan: {
        handler() {
          this.sortCustomers();
        },
        deep: true,
      },
    },
    mounted() {
      this.$store.commit('VisitPlanStore/setCurrentVisitPlan', this.visitPlan);
      this.$store.dispatch('LocationStore/requestLocation');
    },
    unmounted() {
      this.$store.commit('VisitPlanStore/clearCurrentVisitPlan');
    },
  };
</script>

<style scoped lang="scss">
.visitPlan {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 100px);

  .green-card {
    display: inline-block;
    width: 100%;
    margin: 0 10px 10px 0;
    border-bottom: 2px solid var(--ThemeCardBorderColor);
    text-decoration: none;
    background: var(--ThemeCardColor);
    color: #ffffff;
    padding: 10px;
    font-size: 10px;
  }

  h1 {
    text-transform: uppercase;
    font-size: 18px;
  }

  .map {
    display: block;
    height: 200px;
    margin-bottom: 10px;
  }

  .customer-list {
    h1 {
      margin: 20px 10px 10px 10px;
    }

    .search {
      background: #efefef;
      padding: 10px 20px;
      margin: 0 -10px;
    }

    .sort {
      background: #efefef;
      padding: 0 20px 10px 20px;
      margin: 0 -10px;
      font-size: 12px;

      a {
        color: var(--ThemeLinkColor);
        font-weight: 700;
      }

      .sort_direction {
        margin-left: 10px;
      }

      .sortList {
        list-style: none;
        margin-top: 10px;
        background: #ffffff;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid #bdc3c7;

        li {
          border-bottom: 1px solid rgba(0, 0, 0, 0.07);

          a {
            display: block;
            text-decoration: none;
            font-weight: inherit;
            padding: 10px;
          }
        }
      }
    }

    .customers {
      margin: 0 -10px;
      padding: 0 10px;
      box-shadow: inset 0 2px 1px 0 rgb(0 0 0 / 10%);
      border-top: 1px solid #dcdcdc;
    }
  }
}

</style>
