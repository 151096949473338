<template>
  <a @click="showVisitPlan" class="visit-plan-item">
    <h1>{{ item.name }} ({{ completionPercentage }}%)</h1>
    <p>{{ periodFrom }} - {{ periodTo }}</p>
  </a>
</template>

<script>
  import moment from 'moment';

  import VisitPlan from '@/components/VisitPlans/VisitPlan';
  import PopupOptions from '@/types/PopupOptions';
  import translate from '../Mixins/Translate';

  export default {
    name: 'visit-plan-list-item',
    mixins: [translate],
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      periodFrom() {
        return moment.utc(this.item.periodFrom).local().format('D MMM YYYY');
      },
      periodTo() {
        return moment.utc(this.item.periodTo).local().format('D MMM YYYY');
      },
      customerCount() {
        return this.item.customers.length;
      },
      visitedCustomerCount() {
        return this.item.customers.filter((customer) => customer.visits && customer.visits.length > 0).length;
      },
      completionPercentage() {
        return Math.round((this.visitedCustomerCount * 100) / this.customerCount);
      },
    },
    methods: {
      showVisitPlan() {
        console.log('Show Visit Plan');
        const poptions = new PopupOptions();
        poptions.component = VisitPlan;
        poptions.title = this.item.name; // this.x('visitPlans');
        poptions.direction = 'up';
        poptions.showTakePicture = true;
        poptions.waitForLoad = false;
        poptions.params = {
          visitPlanId: this.item.id,
        };
        this.$store.commit('pushPopup', poptions);
      },
    },
  };
</script>

<style scoped lang="scss">
  .visit-plan-item {
    display: inline-block;
    width: 100%;
    margin: 0 10px 10px 0;
    border-bottom: 2px solid var(--ThemeCardBorderColor);
    text-decoration: none;
    background: var(--ThemeCardColor);
    //border-color: var(--ThemeCardBorderColor);
    color: #ffffff;
    padding: 10px;
    font-size: 10px;

    h1 {
      text-transform: uppercase;
      font-size: 18px;
    }
  }
</style>
