function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

/**
 * Creates a guid with only numbers and no dashes. And shorter.
 * @returns {*}
 */
export function guidNoDash() {
  return `a${s4()}${s4()}${s4()}${s4()}`;
}

export function guid() {
  return `${s4() + s4()}-${s4()}-${s4()}-${
    s4()}-${s4()}${s4()}${s4()}`;
}
