import {
  computed, ref, reactive, watch,
} from 'vue';
import { useStore } from 'vuex';
import { orderBy } from 'lodash';

/**
 *
 * @param customers
 * @returns {Object} customerSorting An object containing the properties and methods to merge into the component.
 * @returns {boolean} customerSorting.sortOptionsVisible
 * @returns {boolean} customerSorting.sortAscending
 * @returns {string} customerSorting.sortLabel
 * @returns {Customer[]} customerSorting.sortedCustomers
 * @returns {Function} customerSorting.setSortProperty
 * @returns {Function} customerSorting.toggleSortOptions
 * @returns {Function} customerSorting.toggleSortDirection
 */
export default function useCustomerSorting(customers) {
  const store = useStore();
  const isGeolocationSupported = store.state.LocationStore.isGeolocationSupported;
  const locationError = store.state.LocationStore.locationError;

  const useDistance = isGeolocationSupported && !locationError;
  const sortProperty = ref(useDistance ? 'distance' : 'name');
  const sortAscending = ref(true);
  const sortOptionsVisible = ref(false);
  const sortedCustomers = reactive({ list: [] });
  const labels = reactive({
    distance: 'distance',
    name: 'name',
    city: 'city',
    address: 'address',
    postalCode: 'postalCode',
  });

  const sortLabel = computed(() => {
    if (sortProperty.value === '' || sortProperty.value === 'sortIndex') return 'standard';
    return labels[sortProperty.value];
  });

  const toggleSortDirection = () => {
    sortAscending.value = !sortAscending.value;
  };

  const toggleSortOptions = () => {
    sortOptionsVisible.value = !sortOptionsVisible.value;
  };

  const setSortProperty = (propName) => {
    sortProperty.value = propName;
    sortOptionsVisible.value = false;
  };

  const sortCustomers = () => {
    const direction = sortAscending.value ? 'asc' : 'desc';
    switch (sortProperty.value) {
      case 'sortIndex':
      case 'name':
        sortedCustomers.list = orderBy(customers, [sortProperty.value], [direction]);
        break;
      default:
        sortedCustomers.list = orderBy(customers, [sortProperty.value, 'name'], [direction, direction]);
        break;
    }
  };

  watch(customers, () => { sortCustomers(); }, { deep: true, immediate: true });
  watch(sortProperty, () => { sortCustomers(); });
  watch(sortAscending, () => { sortCustomers(); });

  return {
    sortProperty,
    sortAscending,
    sortOptionsVisible,
    sortLabel,
    sortedCustomers,
    toggleSortOptions,
    toggleSortDirection,
    sortCustomers,
    setSortProperty,
  };
}
