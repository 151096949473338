<template>
  <div>
    <div v-for="(column, index) in columns" :style="{ width: columnWidthPercent }" class="dynColumn"
         :class="getColumnClasses(index)" :key="index">
      <div v-for="item in column" :key="item.id">
        <slot :item="item" :item-renderer="itemRenderer">No content for the slot.</slot>
      </div>
    </div>
    <div v-if="items.length === 0 && initialized === true" class='emptyList'><i
      class='fa fa-exclamation-circle'/><br>{{ x(noMoreElementsText) }}
    </div>
    <div class='lsc-clear'></div>
    <div v-if="displayCount < items.length">
      <button class="lsc-button lsc-rounded-10 grey full-width" @click="showMoreItems">
        Load more...
      </button>
    </div>
  </div>
</template>

<script>
  import { take } from 'lodash';
  import { mapState } from 'vuex';
  import translate from './Mixins/Translate';

  export default {
    name: 'progressive-list-with-columns',
    props: {
      items: {
        type: Array,
      },
      itemRenderer: {
        type: Function,
      },
      // How many items are added to the display when the user scrolls
      batchSize: {
        type: Number,
        default: 50,
      },
      // How many are shown on initial render, i.e. before any scrolling.
      initialBatchSize: {
        type: Number,
        default: 50,
      },
      // The DOM id of the containing scrolling element.
      // If no element is provided, all elements are loaded at once, since we cannot detect scroll.
      containerId: {
        type: String,
        required: false,
      },
      columnWidth: {
        type: Number,
        default: 140,
      },
      noMoreElementsText: {
        type: String,
        default: 'noMoreElements',
      },
    },
    mixins: [translate],
    data() {
      return {
        columns: [],
        displayCount: this.initialBatchSize,
        columnCount: 0,
        colIndexPrepend: 0,
        colIndexAppend: 0,
        columnWidthPercent: 100,
        initialized: false,
        previousItemCount: 0,
        firstId: null,
        lastId: null,
        hasScroll: false,
      };
    },
    computed: {
      itemClass() {
        return this.$store.state.config.mode === 'app' ? 'Theme_C1_BaseColor' : 'Theme_C1_TXT';
      },
      visibleItems() {
        return take(this.items, this.displayCount);
      },
      ...mapState('WindowStore', ['windowSize']),
    },
    watch: {
      columnWidth() {
        this.redistributeItems();
      },
      items: {
        deep: true,
        handler() {
          const count = this.items.length;
          if (count === this.previousItemCount + 1) {
            if (count > 1) {
              if (this.items[1].id === this.firstId) {
                this.prepend(this.items[0]);
                return;
              }
            }
          }

          this.redistributeItems();
        },
      },
      windowSize: {
        deep: true,
        handler() {
          this.redistributeItems();
        },
      },
    },
    async mounted() {
      this.redistributeItems();
      setTimeout(() => {
        this.initialized = true;
      }, 500);
    },
    methods: {
      checkForScroll() {
        const containerElement = this.getContainerElement();
        if (containerElement === null) {
          console.log('No container element');
          return false;
        }
        if (this.items.length > this.initialBatchSize && this.hasScroll === false) {
          this.hasScroll = true;
          containerElement.scroll(() => {
            this.handleScroll();
          });
        }
        return true;
      },
      getContainerElement() {
        if (!this.containerId) {
          return null;
        }

        return $(`#${this.containerId}`);
      },
      handleScroll() {
        const containerElement = this.getContainerElement();
        const distanceFromBottom = containerElement.prop('scrollHeight')
          - (containerElement.scrollTop() + containerElement.height());

        if (distanceFromBottom < 750) {
          this.showMoreItems();
        }
      },

      showMoreItems() {
        if (this.displayCount === this.items.length) return;
        const oldDisplayCount = this.displayCount;
        const newDisplayCount = Math.min(this.displayCount + this.batchSize,
                                         this.items.length);
        for (let i = oldDisplayCount; i < newDisplayCount; i += 1) {
          this.append(this.items[i]);
        }

        this.displayCount = newDisplayCount;
      },

      redistributeItems() {
        const containerElement = this.getContainerElement();
        if (containerElement === null) return;
        const containerWidth = containerElement.width();

        this.columnCount = Math.floor(containerWidth / this.columnWidth);
        if (this.columnCount === 0) {
          return;
        }
        this.columnWidthPercent = `${100 / this.columnCount}%`;

        this.columns = [];
        for (let i = 0; i < this.columnCount; i += 1) this.columns.push([]);

        this.colIndexPrepend = 0;
        this.colIndexAppend = 0;
        if (this.displayCount === 0) this.displayCount = this.initialBatchSize;

        this.visibleItems.forEach(this.append);
        this.calculateIndices();
        this.checkForScroll();
      },
      append(item) {
        this.columns[this.colIndexAppend].push(item);
        this.colIndexAppend += 1;
        if (this.colIndexAppend === this.columnCount) this.colIndexAppend = 0;
        this.calculateIndices();
      },
      prepend(item) {
        this.columns[this.colIndexPrepend].unshift(item);
        this.colIndexPrepend += 1;
        if (this.colIndexPrepend === this.columnCount) this.colIndexPrepend = 0;
        this.calculateIndices();
      },
      calculateIndices() {
        // These are used to detect if changes in items are a single append or other changes.
        this.previousItemCount = this.items.length;
        if (this.items.length === 0) {
          this.firstId = null;
          this.lastId = null;
        } else {
          this.firstId = this.items[0].id;
          this.lastId = this.items[this.items.length - 1].id;
        }
      },
      getColumnClasses(index) {
        const firstClass = index === 0 ? 'firstColumn ' : '';
        const lastClass = index === this.columns.length - 1 ? 'lastColumn ' : '';
        return firstClass + lastClass;
      },
    },
  };
</script>

<style>
  button.lsc-button.full-width {
    width: 100%;
    text-align: center;
  }

  .grey {
    border-bottom: 2px solid #cecece;
    background: #f0f0f0;
    color: #949494;
  }
</style>
