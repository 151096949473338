import { ref, watch, reactive } from 'vue';

export default function useCustomerFilter(customers) {
  const searchText = ref('');
  const filteredCustomers = reactive({ list: [] });

  const filterCustomers = () => {
    if (searchText.value === '') {
      filteredCustomers.list = customers.list;
    }

    const lowercaseFilter = searchText.value.toLowerCase();
    filteredCustomers.list = customers.list.filter((v) => {
      if (v.name && v.name.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
      if (v.address && v.address.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
      if (v.city && v.city.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
      if (v.postalCode && v.postalCode.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
      if (v.extra1 && v.extra1.toLowerCase().indexOf(lowercaseFilter) >= 0) return true;
      return false;
    });
  };

  watch(customers, () => { filterCustomers(); }, { deep: true, immediate: true });
  watch(searchText, () => { filterCustomers(); });

  return {
    searchText,
    filteredCustomers,
  };
}
