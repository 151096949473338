/**
 * This describes the options available when inserting a popup window.
 */
export default class PopupOptions {
  /**
   * The type of component to show in the popup.
   * @type {Object}
   */
  component = null;

  /**
   * The title of the popup. This is shown in the top bar. The title should be a translation key.
   * @type {string}
   */
  title = '';

  /**
   * This describes how the popup will appear. Possible values are 'up' and 'down'.
   * @type {string}
   */
  direction = '';

  /**
   * This flag indicates whether the title suffix field should be shown.
   * The suffix to be shown can be set with the vuex setTopBarSuffix mutation.
   * @type {boolean}
   */
  showSuffix = false;

  /**
   * This indicates that this popup is modal or transitional, i.e. when the next popup
   * is pushed, this should be removed.
   * @type {boolean}
   */
  hideWhenShowingNext = false;

  /**
   * This flag indicates if the popup should be a little less than full height in order to
   * still show the Take Picture bottom bar.
   * @type {boolean}
   */
  showTakePicture = false;

  /**
   * This indicates that the component is going to load some data. In that case,
   * a spinner will show until the component emits a 'didLoad' event.
   * If false, the spinner will not be shown.
   * @type {boolean}
   */
  waitForLoad = true;
}
