<template>
  <div class='element materialShadow scrollList' :id="containerId">
    <progressive-list-with-columns :items="visitPlans"
                                   :containerId="containerId"
                                   :columnWidth="280"
                                   no-more-elements-text="noVisitPlans">
      <template v-slot="itemData">
        <visit-plan-list-item :item="itemData.item" />
      </template>
    </progressive-list-with-columns>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { guid } from '@/classes/guid';

  import ProgressiveListWithColumns from '@/components/ProgressiveListWithColumns';
  import VisitPlanListItem from '@/components/VisitPlans/VisitPlanListItem';
  import translate from '../Mixins/Translate';

  export default {
    name: 'visit-plan-list',
    components: {
      ProgressiveListWithColumns,
      VisitPlanListItem,
    },
    mixins: [translate],
    data() {
      return {
        containerId: guid(),
      };
    },
    computed: {
      ...mapState('VisitPlanStore', ['visitPlans', 'refreshAvailable']),
    },
  };
</script>

<style scoped>

</style>
